import { Component, OnInit, Renderer2 } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    private renderer: Renderer2
  ) {
    this.dateAdapter.setLocale('de');
  }

  public ngOnInit() {
    this.addTrackingScript();
  }

  addTrackingScript() {
    if (environment.umamiId) {
      const script = this.renderer.createElement('script');
      script.src = 'https://umi.wastics.eu/script.js';
      script.defer = true;
      script.setAttribute('data-website-id', environment.umamiId);
      this.renderer.appendChild(document.head, script);
    }
  }
}
